@import 'variables';

.button {
	color: $base-white;
	border: 1px solid transparent;
	padding: 5px 15px;
	border-radius: 10px;
	font-family: $base-font;
	font-style: normal;
	font-weight: $w700;
	font-size: 14px;
	line-height: 18px;

	&--primary {
		background-color: $teal;
	}

	&--primary:hover {
		filter: drop-shadow(0px 0px 4px #04bf8a);
	}

	&--primary-disabled {
		background: #5b5b5b;
	}

	&--secondary {
		border: 2px solid $teal;
		color: $teal;
	}

	&--secondary:hover {
		background: #dde3e9;
	}

	&--trietary {
		color: #5e89a5;
		border-color: #333;
	}

	&--icon {
		background-color: $transparent;
		color: $neutral-black;
		cursor: pointer;
	}

  &--link:hover {
    background-color: $transparent;
    color: $yellow;
    cursor: pointer;
  }

  &--link {
    background-color: $transparent;
    color: $teal;
    cursor: pointer;
  }

  &--minimal-link {
    background-color: $transparent;
    color: $grey;
    cursor: pointer;
  }
}
