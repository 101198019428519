@import 'variables';

.typography {
	font-family: $base-font;

	&.title {
		font-style: normal;
		font-weight: $w700;
		font-size: 56px;
		line-height: 73px;
		letter-spacing: -1.25px;
	}

	&.header-1 {
		font-style: normal;
		font-weight: $w500;
		font-size: 40px;
		line-height: 120%;
	}

	&.header-2 {
		font-style: normal;
		font-weight: $w500;
		font-size: 32px;
		line-height: 128%;
	}

	&.subtitle {
		font-style: normal;
		font-weight: $w500;
		font-size: 24px;
		line-height: 140%;
	}

	&.body-large {
		font-style: normal;
		font-weight: $w400;
		font-size: 20px;
		line-height: 165%;
	}

	&.body-regular {
		font-style: normal;
		font-weight: $w700;
		font-size: 14px;
		line-height: 24px;
	}

	&.body-small {
		font-style: normal;
		font-weight: $w400;
		font-size: 14px;
		line-height: 155%;
	}

	&.button {
		font-style: normal;
		font-weight: $w700;
		font-size: 14px;
		line-height: 18px;
	}

	&.tooltip {
		font-style: normal;
		font-weight: $w400;
		font-size: 12px;
		line-height: 13px;
	}

	&.huge {
		font-style: normal;
		font-weight: $w500;
		font-size: 200px;
		line-height: 128%;
	}
}
